import { Select } from '@mantine/core';
import { getSelectProps } from '~/pages/strategy_center_admin/_utils/getSelectProps';
import { pageConfig } from '~/pages/strategy_center_admin/pageConfig';
export function CurrencySelect(props) {
    return (<Select label='幣別' searchable hoverOnSearchChange {...getSelectProps({
        value: props.value || 'TWD',
        data: pageConfig.currencyOptions,
        onChange: changed => {
            props.onChange(changed.value);
        },
    })}/>);
}
