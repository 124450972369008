import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
/**
 * 給定一個數字，然後返回千分位格式化後的數字字串
 *
 * @example
 *   //
 *   representationAsGlobal(12345667) // '12,345,667'
 *   representationAsGlobal('+12345667.9598%') // '+12,345,667.9598%'
 */
export const representationAsGlobal = (value) => {
    if (isNaN(value))
        return '';
    const value_ = typeof value === 'string' ? Number(value.replaceAll(/[,\+\-\=%\s]/gim, '')) : value;
    if (!isNumber(value_) || isNaN(value_)) {
        console.warn(`${representationAsGlobal.name}: expect value to be number, but get ${value}`);
        return '';
    }
    const numberSplit = String(value).split('.');
    const converted = numberSplit[0]?.replaceAll(/(\d)(?=(\d{3})+$)/gim, '$1,') || String(value);
    return `${converted}${numberSplit.length >= 2 ? '.' : ''}${numberSplit[1] || ''}`;
};
