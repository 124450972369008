import { Select } from '@mantine/core';
import { useProxy } from 'valtio/utils';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export function InstrumentSelector(props) {
    const state = useProxy(pageStore.instrumentsStore);
    return (<Select label={props.label} value={props.value} searchable hoverOnSearchChange filter={(value, datum) => {
            return ((datum.value.toLowerCase().includes(value.toLowerCase()) ||
                datum.label?.toLowerCase().includes(value.toLowerCase())) ??
                false);
        }} data={state.instruments.map(instrument => ({
            label: `${instrument.displayName} ${instrument.symbolRoot}` || instrument.symbolRoot,
            value: instrument.symbolRoot,
        }))} onChange={value => {
            if (!value) {
                console.info(`🔴 <InstrumentSelector onChange /> 找不到 value。value 為 ${value}`);
                return;
            }
            props.onChange(value);
        }}/>);
}
