import { css } from '@emotion/react';
import { IconEqual, IconChevronsDown, IconChevronsUp } from '@tabler/icons-react';
import { Fragment } from 'react';
import { component } from '~/utils/component';
import { representationAsGlobal } from '~/utils/representationAsGlobal';
export const NumberDisplay = component(props => {
    let value = props.value ?? 0;
    const prevValue = props.prevValue ?? 0;
    if (props.showAsPercentage) {
        value *= 100;
    }
    let valueString = props.numberToFixed ? value.toFixed(props.numberToFixed) : String(value);
    if (props.thousandCommaSymbol) {
        valueString = representationAsGlobal(valueString);
    }
    return (<div className={props.className} css={css `
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;

        ${props.showColored === true &&
            (value === prevValue
                ? css `
              color: rgb(158, 158, 158);
            `
                : value > prevValue
                    ? css `
              color: rgb(198, 40, 40);
            `
                    : value < prevValue
                        ? css `
              color: rgb(46, 125, 50);
            `
                        : css ``)}
      `}>
      {props.showTrendingIcons === true && (<Fragment>
          {value > 0 ? (<IconChevronsUp data-change={value}/>) : value < 0 ? (<IconChevronsDown data-change={value}/>) : (<IconEqual />)}
        </Fragment>)}

      <div>
        {props.showTrendingSymbols === true && (props.value > 0 ? '+' : null)}
        {valueString}
        {props.showAsPercentage === true && '%'}
        {props.showPercentSymbol === true && '%'}
      </div>
    </div>);
});
