import { isDayjs } from 'dayjs';
import { isEmpty, isString } from 'lodash';
import { Fragment, useMemo } from 'react';
import dayAPI from '~/utils/dayAPI';
export function DayjsDisplay(props) {
    const value = useMemo(() => {
        const is00010101 = isString(props.value) && props.value.startsWith('0001');
        const $value = isDayjs(props.value) ? props.value : dayAPI(props.value);
        if (is00010101) {
            return '';
        }
        if (props.nilable && isEmpty(props.value)) {
            return '';
        }
        if (!props.nilable && !$value.isValid()) {
            console.warn(`預期 runtime:props.value 為合法的 datetime 格式，但收到 runtime:${props.value}`, {
                props,
                'props.value': props.value,
            });
            return '';
        }
        if (props.format === 'date')
            return $value.formatDate();
        if (props.format === 'dateTime')
            return $value.formatDateTime();
        if (props.format === 'dateTimeSeconds')
            return $value.formatDateTimeSeconds();
        return $value.formatDateTime();
        /** eslint: 使用 props.value?.toString() 取代 props.value */
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [props.value?.toString(), props.format, props.nilable]);
    return <Fragment>{value}</Fragment>;
}
