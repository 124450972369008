import { css } from '@emotion/react';
import { Group, Pagination, Select, TextInput, Tooltip } from '@mantine/core';
import { isNaN } from 'lodash';
import { useState } from 'react';
import { useMount } from 'react-use';
export function MantinePagination(props) {
    return (<Pagination.Root value={props.page} total={props.totalPages} onChange={page => {
            props.goto(page);
        }}>
      <Group align='end' position='right' spacing={4} css={css `
          padding: 4px;
        `}>
        <div css={text}>總計 {props.totalCount} 筆, 每頁</div>
        <Select css={css `
            width: 72px;
          `} size={'xs'} value={String(props.pageSize)} data={[
            { value: '250', label: '250' },
            { value: '100', label: '100' },
            { value: '50', label: '50' },
            { value: '25', label: '25' },
        ]} onChange={value => {
            if (value) {
                props.setPagination({ pageSize: Number(value) });
            }
        }} hoverOnSearchChange/>
        <div css={text}>筆, 共有 {props.totalPages}頁</div>

        <Pagination.First />
        <Pagination.Previous />
        <Pagination.Items />
        <Pagination.Next />
        <Pagination.Last />

        <div css={text}>跳至第</div>
        <JumpPageForm onChange={jumpPage => {
            props.goto(jumpPage);
        }}/>
        <div css={text}>頁</div>
      </Group>
    </Pagination.Root>);
}
function JumpPageForm(props) {
    const [jumpPage, setJumpPage] = useState(1);
    useMount(() => {
        if (!props.onChange) {
            console.info(`🟡`, `<JumpPageForm />`, `props.onChange 不存在`, { jumpPage });
        }
    });
    return (<Tooltip label={`跳至第 ${jumpPage} 頁`}>
      <TextInput className={props.className} size='xs' css={css `
          width: 60px;
        `} tabIndex={0} error={isNaN(Number(jumpPage)) || !jumpPage} defaultValue={jumpPage} onChange={event => {
            const value = Number(event.currentTarget.value);
            if (!isNaN(value) && value > 0) {
                setJumpPage(value);
            }
        }} onBlur={() => {
            props.onChange?.(jumpPage);
        }} onKeyDown={event => {
            if (event.key === 'Enter') {
                props.onChange?.(jumpPage);
            }
        }}/>
    </Tooltip>);
}
const text = css `
  align-self: center;
  font-size: 14px;
  padding-top: 4px; // 補上下對齊的誤差，以免字有點上下歪
`;
